module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/web/src/containers/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chris Czach - Full Stack Developer","short_name":"ChrisCzachDeveloper","icon":"src/images/icon.png","start_url":"/","background_color":"#2E253A","theme_color":"#585246","display":"standalone"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
